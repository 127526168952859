.container {
  width: 650px;
  gap: 28px;
}

.container h2 {
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding: 10px 0px;
}

h3 {
  margin: 0;
}

p {
  margin: 0;
}

.top {
  padding: 24px;
}

.down {
  padding: 24px;
  gap: 32px;
}

.profile_details {
  justify-content: space-between;
  padding: 0px 0px 32px 0px;
  border-bottom: 1px solid #efefef;
}

.profile_image {
  padding: 32px 0px;
}

.image_container {
  width: 135px;
  height: 135px;
  border-radius: 8px;
  position: relative;
}

.profile_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit_profile {
  position: absolute;
  background: #f4f4f4;
  color: #cbcbcb;

  top: -9%;
  left: 89%;
}

.profile_information {
  border-top: 1px solid #efefef;
  padding: 32px 0px 0px 0px;
}

.change_email {
  gap: 32px;
}

.password_inputs {
  gap: 32px;
  flex-wrap: wrap;
}

.field {
  width: 40%;
  gap: 5px;
}

label {
  color: #222124;
  font-weight: 600;
}

.action_buttons {
  gap: 16px;
  justify-content: flex-end;
}

.profile_email {
  gap: 4px;
  width: 261px;
}

.profile_name {
  gap: 4px;
  width: 261px;
}

.password_information {
  gap: 4px;
}

.class_password {
  position: relative;
}

.password {
  display: flex;
  position: absolute;
  right: 12px;
  top: 8px;
}