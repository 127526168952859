.formField label {
  font-size: 16px;
  font-weight: 400;
  color: #5a5c60;
  line-height: 24px;
}

.formField input {

  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  color: black;
  height: 100%;
}

.formField .heading {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.formField .radioCheckbox {
  color: #A1A0A3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.icons:hover {
  background: #ddf5f4;
  color: #40a59e;
}