.formField label {
  font-size: 16px;
  font-weight: 600;
  color: #222124;
}

.formField p {
  color: var(--grey-teritary, #a1a0a3);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.staff_heading p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #40a59e;
}
