.container {
  width: 265px;
}

.container h3 {
  font-size: 16px;
  font-weight: 600;
}

.container p {
  font-size: 14px;
  font-weight: 500;
}
