.back_button h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: black;
}

.back_button p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #a1a0a3;
}

.add_group_form {
  width: 50%;
}

.add_group_form p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #a1a0a3;
}

.add_group_form label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #5a5c60;
}

.camera_icon {
  height: 112px;
  width: 112px;
  background: #ddf5f4;
  color: #40a59e;
  font-size: 40px;
  cursor: pointer;
}
