:root {
  /* color variables */
  --brand-primary: #40a59e;
  --base-white: #fff;
  --base-background-light: #f8f8f8;
  --grey-primary: #222124;
  --grey-secondary: #5f5f61;
  --grey-teritary: #a1a0a3;

  /* font size variables */
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Roboto Slab" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #fff !important;
}
.css-kk1bwy,
.MuiButtonBase-root-MuiMenuItem-root {
  background-color: #fff !important;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
  font-family: "Roboto Slab";
}

button:hover {
  background: #308781;
}

input {
  font-family: "Roboto Slab";
  outline: none;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

p {
  margin: 0;
}

textarea {
  resize: none;
  outline: none;
  font-family: "Roboto Slab";
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  height: 140px;
  max-width: 842px;
}

hr {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-style: solid;
  margin: 0px;
}

.font-inter {
  font-family: "Roboto Slab";
}

.pointer {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-reverse-row {
  display: flex;
  flex-direction: row-reverse;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-40 {
  font-size: 40px;
}

.font-48 {
  font-size: 48px;
}

.bg-green {
  background: #40a59e;
}

.bg-light-green {
  background: #e8f6ed;
}

.color-green {
  color: #40a59e;
}

.color-white {
  color: #fff;
}

.bg-navy {
  background: #131b24;
}

.bg-white {
  background: white;
}

.color-brand-secondary {
  color: #1f2327;
}

.top-bottom-padding {
  padding: 20px 0px;
}

.vt-pd-24 {
  padding: 24px 0px;
}

.pd-0 {
  padding: 0;
}

.pd-10 {
  padding: 10px;
}

.pd-12 {
  padding: 0px 12px;
}

.icon-padding {
  padding: 12px;
}

.pd-16 {
  padding: 16px;
}

.pd-24 {
  padding: 24px;
}

.side-pd-70 {
  padding: 0px 70px;
}

.bottom-pd {
  padding: 0px 0px 20px 0px;
}

.pd-28 {
  padding: 28px;
}

.top-padding-0 {
  padding-top: 0px;
}

.small-gap {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.normal-gap {
  gap: 8px;
}

.medium-gap {
  gap: 12px;
}

.large-gap {
  gap: 20px;
}

.gap-24 {
  gap: 24px !important;
}

.gap-28 {
  gap: 28px;
}

.gap-32 {
  gap: 32px;
}

.gap-36 {
  gap: 36px;
}

.gap-40 {
  gap: 40px;
}

.mg-0 {
  margin: 0;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.side-margin {
  margin: 0px 24px;
}

.bt-margin {
  margin-bottom: 15px;
}

.ht-100 {
  height: 100%;
}

.wd-100 {
  width: 100%;
}

.wd-80 {
  width: 80%;
}

.wd-50 {
  width: 50%;
}

.fit-content {
  width: fit-content;
}

.strikethrough {
  text-decoration-line: line-through !important;
  text-decoration-thickness: 2px !important;
}

.secondary-heading {
  font-size: 20px;
  color: #222124;
  font-weight: 600;
}

.subtle-heading {
  font-size: 16px;
  color: #222124;
  font-weight: 600;
}

.subtle-label {
  font-size: 14px;
  font-weight: 500;
  color: #5f5f61;
}

.subtle-font {
  font-size: 14px;
  font-weight: 500;
}

.overflow-auto {
  overflow: auto;
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
  overflow-y: hidden;
}

.dashed-border {
  border: 1px dashed #e6e6e6;
}

.bord-right-gray {
  border-right: 1px solid #cbcbcb;
}

.edit-icon {
  height: 16px;
  width: 16px;
}

.miniscule-square {
  height: 24px;
  width: 24px;
}

.tiny-square {
  height: 36px;
  width: 36px;
}

.action-square {
  height: 44px;
  width: 44px;
}

.big-icon-square {
  height: 60px;
  width: 60px;
}

.square-75 {
  height: 75px;
  width: 75px;
}

.small-square {
  height: 90px;
  width: 90px;
}

.medium-square {
  height: 100px;
  width: 100px;
}

.large-square {
  height: 120px;
  width: 120px;
}

.circular-div {
  border-radius: 50%;
}

.title-color {
  color: #222124;
}

.color-grey-secondary {
  color: #5f5f61;
}

.information-color {
  color: #a1a0a3;
}

.error-color {
  color: #e73f3f;
}

.light-pink-bg-color {
  background: #f7f6ff;
}

.pink-icon {
  background: #e64593;
  color: white;
}

.bg-light {
  background: #f8f8f8;
}

.no-border {
  border: none;
}

.subtle-border {
  border: 1px solid #e0e0e0;
}

.light-border {
  border: 1px solid #efefef;
}

.bg-base {
  background: #f5f5f5;
}

.align-center {
  align-items: center;
}

.align-right {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.just-sp-bwn {
  justify-content: space-between;
}

.bord-rd-6 {
  border-radius: 6px;
}

.bord-rd-8 {
  border-radius: 8px;
}

.color-tertiary-grey {
  color: #a1a0a3;
}

.color-primary-grey {
  color: #222124;
}

.MuiCircularProgress-colorPrimary {
  color: #40a59e !important;
}

/* -----------------Custom Scrollbar--------------------- */

.hidden-scroll::-webkit-scrollbar {
  display: none;
  /* width: 8px;
  height: 8px; */
}

/* .hidden-scroll::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #a1a0a3;
}

.hidden-scroll::-webkit-scrollbar-track,
.overflow-auto::-webkit-scrollbar-track {
  background: #f8f8f8;
}

.hidden-scroll::-webkit-scrollbar-thumb:hover,
.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: #5f5f61;
} */

/* Line Chart */
.line-chart {
  height: 300px !important;
  width: 100% !important;
}

/* Bar Chart PM Service Requests */
/* .pm-service-request-bar-chart {
  height: 100% !important;
} */
