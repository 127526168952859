.main_container {
  width: 95%;
}

.main_container label {
  font-size: 16px;
  font-weight: 400;
  color: #5a5c60;
}

.add_option {
  font-size: 14px;
  font-weight: 700;
  color: #40a59e;
  width: fit-content;
}
