.addGroupForm {
  width: 100%;
  gap: 40px;
  display: flex;
  flex-direction: column;
}

.groupField {
  width: 50%;
}

.groupField label {
  font-size: 16px;
  font-weight: 400;
  color: #5a5c60;
}


.addGroupForm h3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.addGroupForm label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #5F5F61;
}

.addGroupForm p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #A1A0A3;
}

.staffDiv {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #F8F8F8;
  height: fit-content;
  max-height: 280px;
  overflow: scroll;
}

.staffDiv::-webkit-scrollbar {
  display: none;
}