.modal-container {
  position: absolute;
  top: 5%;
  left: 33%;

  display: flex;
  width: 631px;
  padding: 25px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  background: var(--base-white, #fff);
}