/* Card.module.css */

/* Styling for the parent container */
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* Add this to position CustomTag on the right */
}

.cardDiv {
  min-width: 475px;
}

/* Styling for the inside container */
.styleddiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid var(--border-light, #efefef);
  margin: 0 38px 38px 0;
}

/* Styling for the h2 and caption container */
.flex-column {
  display: flex;
  flex-direction: column;
}

.secondary-heading {
  font-size: 20px;
  font-weight: 600;
}

.card-row {
  width: 100%;
  margin-bottom: 20px;
}

.custombuttom {
  font-size: 16px;
  font-weight: 500;
  border: none;
  padding: 10px 16px;
  /* border-radius: 6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 4px; */
}