.formField label {
    font-size: 16px;
    font-weight: 600;
    color: #222124;
  }
  
  .formField p {
   
    color: var(--grey-teritary, #A1A0A3);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
  }
 