.formField label {
  font-size: 16px;
  font-weight: 400;
  color: #5a5c60;
}

.formField input {

  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  color: black;
  height: 100%;
  font-family: Roboto Slab !important;
  font-size: 14px;
  font-weight: 400;
}