.left {
  width: 40%;
  min-width: fit-content;
}

.right {
  width: 60%;
}

.top_row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 28px !important; */
}

.bottom_row {
  display: flex;
  justify-content: space-between;
}

.thirty {
  width: 30%;
  min-width: fit-content;
}

.amount_disbursed {
  width: 100%;
  height: 100% !important;
}

.service_and_amount_row {
  justify-content: space-between;
}

.amount_div_1 {
  flex-direction: row;
  /* width: 300px; */
  width: 30%;
  min-width: fit-content;
}

.amount_div_2 {
  min-height: 204px;
  min-width: auto;
  background: white;
  border-radius: 10px;
  padding: 0px 0px 48px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}