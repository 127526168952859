.service_tag:hover > .icons {
  background: #ddf5f4;
  color: #40a59e;
  fill: #40a59e;
}

.active > .icons {
  background: #ddf5f4;
  color: #40a59e;
  fill: #40a59e;
}


.service_tag {
  fill: #a1a0a3;
}