.formField label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #5a5c60;
}

.addGroupForm {
  width: 100%;
  gap: 40px;
  display: flex;
  flex-direction: column;
}

.staffDiv {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #F8F8F8;
  height: fit-content;
  max-height: 280px;
  overflow: scroll;
}
.staffDiv::-webkit-scrollbar {
  display: none;
}