.left_container {
  width: 30%;
  border-radius: 10px;
}

.list_items {
  border-radius: 6px;
  overflow: hidden;
  min-height: 41px;
}

.left_container section {
  padding: 10px;
  width: -webkit-fill-available;
  text-align: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  display: flex;
  justify-content: center;
}

.right_container {
  width: 70%;
  border-radius: 10px;
}

.activeList {
  background: #d0f2f0;
  color: #40a59e;
}

.chat_slot {
  padding: 16px 24px;
  cursor: pointer;
  position: relative;
}

.chat_slot:hover {
  background: #d0f2f0;
}

.activeRoom {
  background: #d0f2f0;
}

.peopleList {
  height: calc(100% - 130px);
  overflow: auto;
}

.staff_heading h3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #222124;
}

.staff_heading p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #a1a0a3;
}

.unread_dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: red;
}

.timestamp {
  font-size: 10px;
  color: #a1a0a3;
}

.no_chat_found {
  color: #a1a0a3;
  text-align: center;
}

.chatCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: red;
  color: white;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  font-size: small;
}
