.main_container {
  width: 95%;
}

.main_container h2 {
  font-size: 20px;
  font-weight: 700;
}

.main_container p {
  font-size: 14px;
  font-weight: 500;
}

.main_container label {
  font-size: 16px;
  font-weight: 400;
  color: #5a5c60;
}

.main_container form p {
  color: black;
  font-size: 14px;

  font-weight: 600;
}

.category_icon {
  color: #a1a0a3;
  fill: #a1a0a3;
  border: 1px solid #f5f5f5;
}

.category_icon_active {
  /* color: #40A59E; */
  background: #ddf5f4;
  border: 1px solid #40A59E;
  fill: #40A59E;
}

.category_icon:hover {
  color: #40A59E;
  fill: #40A59E;
  background: #ddf5f4;
  border: 1px solid #40A59E;
}

.category_icon svg {
  fill: #a1a0a3
}

.category_icon:hover svg {
  fill: #40A59E;
}