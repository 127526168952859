.sidebar {
  width: 13%;
  background: #1d5fa4;
}

.sidebar a {
  text-decoration: none;
}

.company_logo {
  justify-content: center;
  align-items: center;
  padding: 14px 0px;
  border-bottom: 1px dashed #5f5f61;
}

.company_logo img {
  width: 28%;
  height: auto;
}

.sidebar ul {
  padding: 0px 12px;
  margin: 14px 0px;
  list-style-type: none;
  gap: 20px;
}

.menu_item {
  padding: 12px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.menu_item:hover {
  background: #0a3d73;
  color: white;
}

.menu_item li {
  align-items: center;
  gap: 4px;
}

.active .menu_item {
  background: #0a3d73;
  color: white;
}

.chatCounter {
  height: 25px;
  width: 25px;
  background: red;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
