.navbar {
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 1px 0px rgba(24, 24, 28, 0.04),
    0px 3px 4px 0px rgba(24, 24, 28, 0.04);
}

.navbar h2 {
  padding: 23.25px;
}

.actions {
  align-items: center;
  margin-right: 24px;
  gap: 24px;
}

.profile_enclosing {
  background: white;
}

.profile {
  align-items: center;
}

.profile p {
  margin: 0;
}

.name {
  font-weight: bold;
}
