.react-tel-input .form-control {
  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  padding-left: 3.6rem;
  color: black;
  height: 38px;
  width: 100% !important;
}

.flag-dropdown {
  background-color: var(--base-background-light) !important;
  border: 1px solid #e0e0e0;
}

.react-tel-input .form-control:focus {
  border-color: white;
  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: none;
}
