.main_container {
  width: 95%;
}

.main_container h2 {
  font-size: 20px;
  font-weight: 700;
}

.main_container p {
  font-size: 14px;
  font-weight: 500;
}

.main_container label {
  font-size: 16px;
  font-weight: 400;
  color: #5a5c60;
}

.main_container textarea {
  resize: none;
  height: 80px;
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  font-family: "Roboto Slab";
  font-size: 14px;
  border-radius: 6px;
  outline: none;
  padding: 10px;
}

.image_upload {
  background: #ddf5f4;
  border: 1px dashed #40a59e;
}

.image_upload h3 {
  font-size: 14px;
  font-weight: 600;
}

.image_upload p {
  font-size: 12px;
  font-weight: 400;
  color: #a1a0a3;
}

.main_container input[type="checkbox"] {
  height: 15px;
  width: 15px;
  accent-color: #40a59e;
}

.image_div {
  position: relative;
}

.image_cross {
  position: absolute;
  top: -12%;
  left: 87%;
}

.customPrice {
  width: 100%;
  border-bottom: 1px solid #a1a0a3;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
