.searchInput {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
}

.searchInput::placeholder {
  color: #5f5f61;
  opacity: 0.4000000059604645;
}
