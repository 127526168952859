.root {
  height: 100vh;
}

.left {
  width: 50%;
  background-color: var(--base-white);
}

.login_content {
  text-align: center;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.login_content form {
  margin-top: 2.5rem;
  width: 27.75rem;
}

.login_content h3 {
  font-weight: 600;
  margin: 0;
  color: var(--grey-primary);
}

.login_content p {
  font-weight: 400;
  margin-top: 0.75rem;
  color: var(--grey-secondary);
}

.login_content .login_inputs {
  gap: 20px;
}

.login_content input {
  padding: 10px;
  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.login_content form p {
  margin: 16px 0px 0px 0px;
  text-align: right;
  font-weight: 600;
  color: var(--brand-primary);
}

.login_content form button {
  padding: 10px 16px;
  margin: 40px 0px;
  background: #40a59e;
  border-radius: 6px;
  color: var(--base-white);
  font-size: 16px;
  font-weight: 500;
  border: none;
}
