.drawer {
  width: 30vw;
  padding: 10px;
  /* overflow: hidden; */
}

.individualNotification {
  background: white;
  width: auto;
  padding: 15px;
  border: 1px solid #a1a0a3;
  border-radius: 8px;
}

.notificationHeading {
  text-align: center;
  margin: 20px;
  font-size: x-large;
}
