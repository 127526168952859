.modal-container {
  position: absolute;
  top: 5%;
  left: 33%;
  padding: 25px 20px;
  display: flex;
  width: 631px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  background: var(--base-white, #fff);
}

/* .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
} */

.close-button {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}