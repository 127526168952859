.chart_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart_container h3 {
  font-size: 24px;
  font-weight: 700;
}

.chart_container p {
  font-size: 16px;
  font-weight: 500;
  color: #a1a0a3;
}

.doughnut_container {
  position: relative;
}

.canvas {
  position: relative;
  z-index: 2;
  width: 300px;
}

.canvas_properties {
  position: relative;
  z-index: 2;
  width: 390px;
}

.chart_details {
  position: absolute;
  top: 35%;
  left: 12%;
  z-index: 0;
}

.chart_numbers {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.chart_numbers_properties {
  display: flex;
  flex-direction: column;
  gap: 4.5px;
  font-size: "10px";
}

.chart_numbers p {
  font-size: 14px;
  font-weight: 600;
  color: #5f5f61;
}

.chart_numbers_properties p {
  font-size: 14px;
  font-weight: 600;
  color: #5f5f61;
}