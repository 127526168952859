.activity_container h3 {
  font-size: 14px;
  font-weight: 600;
  color: #222124;
}

.activity_container p {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  color: #a1a0a3;
}