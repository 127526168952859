.data_container {
  gap: 32px 120px;
}

.instructions {
  font-size: 14px;
  color: #a1a0a3;
  font-weight: 400;
}

.question {
  font-size: 14px;
  font-weight: 600;
  color: #8f5687;
}

.imageClass {
  font-size: 14px;
  font-weight: 500;
  color: #a1a0a3;
  padding-top: 4px;
}