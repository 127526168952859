.formField label {
  font-size: 16px;
  font-weight: 400;
  color: #5a5c60;
}

.formField input {
  /* padding: 12px;
   font-size: 14px;
   font-weight: 400;
   color: #222124;
   border: 1px solid #222124;
   box-shadow: 0px 0px 0px 3px #ebecf0;
   border-radius: 6px; */

  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  color: black;
  height: 100%;
}
