.details p {
    color:  #A1A0A3;
text-align: center;
font-family: Roboto Slab;
font-size: 14px;
font-weight: 500;
}
.details label{
    color: #000;

font-family: Roboto Slab;
font-size: 20px;
font-weight: 700;
line-height: 30px; 
}