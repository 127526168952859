.right {
  background-color: var(--brand-primary);
}

.right_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.company_slogan img {
  width: 30%;
  height: auto;
}

.right h1 {
  margin: 0;
  font-weight: 700;
}

.company_slogan {
  align-items: center;
  text-align: center;
  gap: 57px;
  margin-top: 120px;
}

.cleaning_model img {
  max-width: 110%;
  max-height: 90%;
}

.cleaning_model {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
