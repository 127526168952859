.staff_list {
  max-height: 336px;
  overflow: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.staff_list::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.formFieldTwo {
  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  color: black;
}

.formField textarea {
  background: var(--base-background-light);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  color: black;
  height: 100%;
}