.right_container {
  /* height: calc(100vh - 265px); */
}

.top_half {
  height: calc(100% - 82px);
}

.input_form {
  /* border: 1px solid #efefef;
  border-radius: 8px; */
}

.input_form input {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border: 1px solid #efefef;
  border-radius: 8px;
}

.input_form input::placeholder {
  color: #cbcbcb;
}

.chat_input {
  width: -webkit-fill-available;
  padding: 10px 16px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}

.chat_heading h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #222124;
}

.chat_heading p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #a1a0a3;
}

.job_due_date {
  padding: 10px 16px;
  border-radius: 6px;
  background: #f8f8f8;
  color: #222124;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.chat_component {
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: calc(100% - 115px);
}

.chat_message h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #222124;
}

.chat_message p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #a1a0a3;
}

.sending_message {
  width: fit-content;
  padding: 18px 16px;
  background: #e4fbff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #222124;
  border-radius: 8px;
}

.incoming_message {
  width: fit-content;
  padding: 18px 16px;
  background: #f8f8f8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #222124;
  border-radius: 8px;
}

.mediaPreview {
  width: max-content;
  padding: 5px;
  background: #d0f2f0;
  border-radius: 5px;
  margin-bottom: 10px;
}

.media_cross {
  cursor: pointer;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  color: white;
  top: -16%;
  background: rgb(237 54 54);
  position: absolute;
  right: -5px;
  display: flex;
  /* text-align: center; */
  /* align-items: center; */
  justify-content: center;
  font-size: xx-small;
}
