.active_background {
  background: #ddf5f4;
  color: #40a59e;
}

.left_side::-webkit-scrollbar {
  display: none;
}

.right_side::-webkit-scrollbar {
  display: none;
}

.left_side hr {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-style: solid;
  margin: 0px;
}

.update_name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #1f2327;
}

.update_date {
  font-size: 16px;
font-style: normal;
font-weight: 400;
color: #A1A0A3;
}

.createdUpdate {
  padding-bottom: 32px;
  border-bottom: 1px solid #E6E6E6;
}
