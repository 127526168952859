.container {

    width: fit-content;
    background: white;
    position: absolute;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    border-radius: 4px;
    right: 450px;
    margin-top: 50px;
}

.date_picker {
    width: 350px;
}

.date_picker p {
    font-size: 16px;
    font-weight: 400;
    color: #5a5c60;
}