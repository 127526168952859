.left {
  width: 40%;
}

.right {
  width: 60%;
  max-height: 100% !important;
}

.users_table_div {
  height: 50%;
}

.revenue_table_div {
  height: 50%;
}

.chart_wrapper {
  height: 300px;
  width: 100% !important;
}