.dx-scheduler-group-header {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-family: Roboto Slab !important;
}

.dx-field-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
}

.dx-field-item-label-text {
  color: #5a5c60;
  font-family: Roboto Slab !important;
  font-size: 16px;
  font-weight: 400;
}

.dx-popup-normal {
  max-width: fit-content !important;
}

.dx-scheduler-date-time-indicator {
  visibility: hidden !important;
}

.dx-texteditor-container,
.dx-texteditor-input-container input {
  height: 40px !important;
  background: var(--base-background-light);
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  font-family: Roboto Slab !important;

  color: black;
}

.dx-scheduler-timeline-week
  .dx-scheduler-date-table-row
  .dx-scheduler-cell-sizes-horizontal {
  width: 100px !important;
  height: 200px !important;
}

.dx-scheduler-timeline-day
  .dx-scheduler-date-table-row
  .dx-scheduler-cell-sizes-horizontal {
  width: 100px !important;
  height: 200px !important;
}

.dx-scheduler-timeline-week .dx-scheduler-appointment {
  height: 35px !important;
}

.dx-scheduler-timeline-day .dx-scheduler-appointment {
  height: 35px !important;
}

.dx-tooltip-appointment-item-content-date {
  display: none !important;
}
/* 

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  height: 20px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll-content {
  height: 20px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  height: 20px !important;
} */

.dx-scrollbar-horizontal {
  height: 20px !important;
}

.dx-scrollable-scroll {
  height: 20px !important;
}

.dx-popup-done {
  background-color: var(--brand-primary);
  color: white;
}

.dx-popup-cancel {
  background-color: #f8f8f8;
  color: #5f5f61;
}

.dx-popup-done:hover {
  background-color: #308781;
  color: white;
}

.dx-popup-bottom {
  border: none !important;
  background-color: white !important;
}

.dx-button-has-text {
  font-family: Roboto Slab !important;

  font-size: 16px;
  font-weight: 500;

  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-form-validation-summary {
  display: none;
}

.dxSelectBox {
  dropDownOptions {
    height: 200px;
  }
}

.dx-checkbox {
  display: flex;
  position: absolute;
  right: 272px;
  bottom: 8px;
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: #308781;
}

.dx-scheduler-work-space-week .dx-scheduler-time-panel {
  display: none;
}

.dx-scheduler-appointment {
  border-radius: 8px !important;
}
